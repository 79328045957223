import $ from "jquery"
import "./../node_modules/bootstrap/js/dist/collapse.js"

import "./assets/styles/main.scss"

$(document).ready(function() {
  var keeper = 0
  var diff = 0
  var sum = 0

  $("#info .h1").hover(function() {
      if ($(this).not(".active")) {
          $("#imageForHover").toggleClass("d-lg-block").attr("src", $(this).attr("data-thumbnail-src"));
          
        } else {
      }
  })

  $(".collapse .closing-button img").hover(function() {
    $(this).attr("src", function(index, attr) {
      return attr == "assets/images/close-hover.svg" ? "assets/images/close.svg" : "assets/images/close-hover.svg"
    })
  })

  $("#info .h1").click(function() {
    if (!$(this).hasClass("active")) {
      $(this).toggleClass("active")
    } else {
      $(this).toggleClass("active")
    }

    $("#info").removeClass("justify-content-end")
    $(".outerbox").removeClass("h-100")

})

$(".collapse").on("show.bs.collapse", function() {
    keeper += 1
    $("html, body").animate(
      { scrollTop: $("a[href$='" + this.id + "']").offset().top },
      200
    )
})

$(".collapse").on("hide.bs.collapse", function() {
    keeper -= 1
    if (keeper<1) {
        $("#info").css("padding-bottom", 0).addClass("justify-content-end")
        $(".outerbox").addClass("h-100")
    }

    $("a[href$='" + this.id + "']").removeClass("active")
  })
})
